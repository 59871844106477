<template>
<div id="orderManage">
  <!--头部搜索-->
  <div class="bg-white searchDom">
    <div class="flex align-start justify-end margin-bottom-24 header_search">
      <div class="flex align-center solid margin-right-24 padding-lr-16" style="height: 32px; width: 300px">
        <el-input @input="emptyKeyword" v-model="getListData.keyword" placeholder="请输入订单编号查询" suffix-icon="search"></el-input>
          <i style="cursor: pointer" @click="searKeyword" class="el-icon-search"></i>
      </div>
      <el-button @click="exportTable" style="height: 32px;width: 68px;padding: 0;color: #4e9f5b !important;border-color: #4e9f5b;">导出</el-button>
    </div>
  </div>
  <!--搜索列表-->
  <div class="searchList">
    <ul class="row align-center margin-bottom-16 flex-start">
      <li class="bg-white padding-16 flex flex-direction justify-between margin-bottom-16" 
          v-for="(v,i) in dataList" :key="i">
          <div class="order">
            <div class="order_number flex justify-between"  @click="navigate('orderDetail',v.order_id)">
              <h1>订单编号:{{v.order_number}} </h1>
              <font color="#4D9859">{{v|orderState}}</font>
            </div>
            <div class="order_title flex justify-between ">
              <div class="flex">
                <h1>标题：</h1>
                <p>{{v.order_wutu_title}}</p>
              </div>
              <!-- 打印类型 -->
              <font>{{v|orderCategory}}</font>
            </div>
            <div class="order_kd flex justify-between align-center">
              <div class="flex align-center">
                 <img v-if="v.order_express_logo !=null" :src="v.order_express_logo">
                 <img v-if="v.order_express_logo ==null" style="display:none">
                 <i v-if="v.order_express_logo !=null" style="height"></i>
                 <span v-if="v.order_express_number != '' && v.order_express_number != null">{{v.order_express_number}}</span>
                 <span v-if="v.order_state != '3' && (v.order_express_number == '' ||
                      v.order_express_number == null)">无快递单号</span>
                 <span v-if="v.order_state == '3'"
									    @click="addOrderNum(v.order_id, v.order_express_company)" style="cursor:pointer">录入快递单号</span>      
              </div>
              <a v-show="v.order_express_number != '' && v.order_express_number != null" @click="copyExpressNum">复制</a>
            </div>
            <div class="order_con">
              <div class="flex">
                <h1>订单来源：</h1>
                <p>{{v.order_wutu_source}}</p>
              </div>
              <div class="flex">
                <h1>支付时间：</h1>
                <p v-if="v.order_pay_time != null">{{v.order_pay_time*1000|getDate}}</p>
              </div>
            </div>
            <div class="order_con flex align-center justify-between">
              <div class="flex">
                <h1>下单人员：</h1>
                <p>{{v.order_admin_acc==null?'--':v.order_admin_acc}}--</p>
              </div>
              <div class="flex align-center" id="order_state">
                <el-button 
                     v-if="v.order_state == '1' || v.order_state == '2'" 
                     @click="editOrderState(v.order_id, v.order_state)" type="text" size="medium">
					         设置{{v.order_state == "1" ? "打印中" : "待发货"}}
                </el-button>
					      <span v-if="v.order_state != '1' && v.order_state!='2'">-</span>
              </div>
            </div>
            <div class="order_desc flex align-center justify-between">
              <div class="flex">
                <span>重量：</span>
                <em>{{v.order_weight_value}}kg</em>
              </div>
              <div class="flex">
                <span>数量:</span>
                <em>{{v.order_print_file_total}}份</em>
              </div>
              <div class="flex">
                <span>页数:</span>
                <em>{{v.order_print_page_total}}页</em>
              </div>
            </div>
          </div>
      </li>
    </ul>
  </div>
  <!--分页-->
  <div class="pageNum flex align-center justify-center" id="pageNum">
    <Pagination
      v-show="rows>1"
      :total="rows"
      @change = "getOrderList"
      :page.sync= "getListData.page"
      :limit.sync="getListData.limit"/>
  </div>
  <!-- 录入快递单号 -->
  <el-dialog id="order_model" close="closeAddDialogVisible" title="录入快递单号" :visible.sync="addDialogVisible" width="30%">
			<el-form id="form" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px" class="demo-ruleForm">
				<el-form-item id="T_item" prop="order_express_company_real">
					<el-select @change="sss" style="width: 100%; background-color: #f5f6f7" v-model="strorder"
						filterable placeholder="请选择快递公司">
						<el-option v-for="item in this.$conf.orderCopam" :key="item.id" :label="item.name" :value="item.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="快递单号" prop="order_express_number">
					<el-input v-model="ruleForm.order_express_number" placeholder="请输入快递单号"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="submitForm('ruleForm')">提 交</el-button>
			</span>
		</el-dialog>
</div>
</template>
<script>
import {orderList,orderManageExpress,orderManagePrint,
orderManageRemark,orderManageRemarkAdd,
orderManageRefundAsk,orderManageSetState} from '@/api/http'
import Pagination from '@/components/pagination'
export default {
    data(){
        return{
          strorder:'',
          ruleForm: {
					  order_id: "",
				  },
          rules: {
					  order_express_number: [{
						  required: true,
						  message: "请填写快递单号",
						  trigger: "blur",}],
				  },
				  options: [],
				  // order_express_company_real: '' //非必填;实际发货快递公司(默认为用户指定快递公司)
				  express: "",
          addDialogVisible:false,
  
				  all_fileid: '',
          /* 分页参数*/
          pages:1, //总页数
          rows:1, //总条数
          /* 获取列表参数 */
			    getListData: {
			     	order_category: "",
			     	order_state: "",
			     	keyword: "",
			     	page: 1,
			     	limit: 8,
			    },
          /* 查看快递单号*/
			    dialogVisible_: false,
			    orderExpressData: [{
			    	order_express_company: "",
			    	order_express_company_real: "",
			    	order_express_number: "",
			    }, ],
          dataList:[],//表格数据
        }
    },
    created(){
        this.getOrderList()
    },
    filters: {
		orderState(val) {
			let arr = [
				"待支付",
				"待打印",
				"打印中",
				"待发货",
				"已发货",
				"已取消",
				"已完成",
			];
			let state = arr[Number(val.order_state)];
			return state;
		},
		orderCategory(val) {
			let arr = ["", "文件和图片打印", "证件照打印", "照片冲洗"];
			let category = arr[Number(val.order_category)];
			return category;
		},
	},
  methods:{
    //一键复制
    copyExpressNum(info) {
			var input = document.createElement("input"); // js创建一个input输入框
			input.value =
				info.order_express_number; // 将需要复制的文本赋值到创建的input输入框中
			document.body.appendChild(input); // 将输入框暂时创建到实例里面
			input.select(); // 选中输入框中的内容
			document.execCommand("Copy"); // 执行复制操作
			document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
			this.$message.success("复制成功");
		},
    navigate(name,id){
			this.$router.push({
				name,
				params:{ id }
			})
		},
    closeAddDialogVisible(){
      this.addDialogVisible = false
      this.getOrderList()
    },
    //录入快递单号
    addOrderNum(id,type){
      console.log(type)
      this.ruleForm.order_id = id
      this.strorder = type
      this.ruleForm.order_express_company_real = type
      this.addDialogVisible = true
    },  
    sss(e) {
			this.ruleForm.order_express_company_real = ''
			this.ruleForm.order_express_company_real = e
		},
    //提交输入快递单号查询
		submitForm(formName) {
			let that = this;
			this.$refs[formName].validate((valid) => {
				if (valid) {
					console.log(this.ruleForm);
					// return false
					orderManageExpress(this.ruleForm)
						.then((res) => {
							console.log(res);
							if (res.data.code == "1") {
								that.$message({
									message: "录入成功",
									type: "success",
								});
								that.$refs[formName].resetFields();
								that.getOrderList();
							}
						});
					this.dialogVisible = false;
				} else {
					console.log("error submit11!!");
					return false;
				}
			});
		},
    //获取订单列表
    getOrderList(){
        orderList(this.getListData).then(res=>{
            console.log('订单列表',res.data)
            let {list,page,count,limit}=res.data.data
            console.log(res.data.code,list,page,count,limit)
            if(res.data.code == '1' && list){
                this.rows = count
                this.dataList = list
            }else if(res.data.code=='1'&&res.data.data.csv_file){
               window.open(res.data.data.csv_file)
               this.getListData.limit = this.rows
            }
        })
    },
    //改变订单状态
    changeState(e){
        this.getListData.page = 1
        this.getListData()
    },
    //改变打印类型
    changeCate(e){
        this.getListData.page = 1
        this.getOrderList()
    },
    /* 搜索*/
		searKeyword() {
			this.getListData.page = 1;
			this.getOrderList();
		},
		/* 搜索框为空 */
		emptyKeyword(e) {
			console.log(e.length);
			if (e.length == "0") {
				this.getListData.page = 1;
				this.getOrderList();
			}
		},
		/* 导出*/
		exportTable() {
			this.getListData.limit = 9999;
			this.getListData.page = 0;
			this.getOrderList();
		},
    //设置订单状态
    editOrderState(order_id, order_state){
      let that = this
      this.$confirm("此操作将永久修改订单状态, 是否继续?", "提示", {
		    confirmButtonText: "确定",
		    cancelButtonText: "取消",
		    type: "warning",
		  })
		  .then(() => {
		  	orderManageSetState({
		  			order_id: order_id,
		  			order_state: Number(order_state) + 1,
		  		})
		  		.then((res) => {
		  			if (res.data.code == "1") {
		  				that.getOrderList();
		  				that.$message.success("设置成功");
		  			} else {
		  				that.$message.error("设置失败");
		  			}
		      });
          })
			    .catch(() => {
			    	this.$message({
			    		type: "info",
			    		message: "已取消操作",
			    	});
			    });
        },
    },
    components:{
        Pagination
    }
}
</script>
<style>
#orderManage{
  width: 100%;
}
#orderManage .header_search .el-input__inner {
  height: 20px !important;
  border: none !important;
  padding: 0 !important;
}
#orderManage ul{
  list-style: none;
  margin-left: -16px;
  margin-right: 16px;
  padding-bottom: 26px;
}
#orderManage .searchList li{
  width: 24%;
  margin-right: 15px;
}
#orderManage .searchList li:hover{
  cursor: pointer;
}
#orderManage ul li .order_number{
  border-bottom: 1px solid #ebedf0;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
#orderManage ul li .order h1 {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  color: #323233;
  display: block;
  margin-top: 2px;
}
#orderManage ul li .order font {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  display: block;
  float: right;
}
.order #order_state{
  margin-top: -15px;
}
.order span{
    display: block;
    white-space: nowrap;
    color: #323233;
    font-size: 14px;
    font-weight: bold;
}
.order em{
  display: block;
  white-space: nowrap;
  color: #323233;
  font-size: 14px;
}
.order p {
    color: #323233;
    font-size: 14px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 200px;
}
.order .order_kd{
  padding: 10px 14px;
  background: rgba(235,237,240,.3);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  margin-bottom: 20px;
}
.order .order_kd img{
  display: block;
  width: 80px;
  height: 36px;
  object-fit: cover;
  background: #ebedf0;
}
.order .order_kd i{
  display: block;
  width: 1px;
  height: 17px;
  background: #ebedf0;
  margin: 0 16px;
}
.order .order_kd .copy{
  display: block;
  white-space: nowrap;
  color: #79838b;
  font-size: 14px;
}
/* 查看快递单号弹窗*/
#orderManage #order_express_model .el-dialog {
	width: 600px;
	border-radius: 4px;
}
#orderManage #order_express_model .el-dialog__header {
	text-align: left;
}
#orderManage #order_express_model .el-dialog__header .el-dialog__title {
	font-size: 16px;
	color: #000000;
	font-weight: bold;
} 
</style>